
.rlc-page-content {
    display: flex;
    gap: 30px;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box; 
}

.rlc-search-result {
    margin-bottom: 20px;
    width: 100%; 
    box-sizing: border-box;
}

button {
    padding: 5px;
    margin: 10px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    max-width: 100%;
}

button:hover {
    background-color: #ec6ead93;
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.rlc-field {
    padding: 10px;
    margin-right: 10px;
    width: 100%; 
    max-width: 300px; 
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.rlc-field:focus {
    outline: none;
    border-color: #ec6ead93;
}

.rlc-book {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    box-sizing: border-box; 
    width: 100%;
    transition: box-shadow 0.2s;
    overflow: hidden; 
}

.rlc-book:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rlc-book-title, .rlc-book-author, .rlc-book-pages, .rlc-book-writtenby, .rlc-book-bookid, .rlc-book-reviewid, .status {
    font-size: 16px;
    margin: 5px 0;
    word-wrap: break-word; 

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rlc-book-title {
    font-weight: bold;
    font-size: 20px;
}


.status-2 {
    color: #ffcc00; 
}

.status-3 {
    color: #28a745; 
}

.status-4 {
    color: #007BFF; 
}

.status-1 {
    color: #dc3545; 
}

.rlc-checkb {
    transform: scale(1.5);
}

.accordian-item {
    display: flex;
    align-items: center;
}

.rlc-status-select {
    width: 120px;
    height: 35px;
    padding: 5px;
    border-radius: 5px;
    justify-self: end;
}

.rlc-admin-control-section {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 20px;
    width: 100%;
}

.rlc-admin-control-section input {
    border: none;
    border-radius: 5px;
}

.rlc-buttons {
    width: 120px;
    height: 35px;
    border-radius: 0;
    font-size: 1.1em;
}

.rlc-search-bar {
    flex: 1;
    height: 35px;
    padding: 5px;
}

.rlc-admin-action-section {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 10px;
    width: 99%;
    border-radius: 5px;
    padding: 2px;
    margin: 10px 0 0 20px;
}

.rlc-admin-action-section button, .rlc-admin-control-section button {
    border: 1px solid black;
    width: 120px;
    height: 35px;
    font-size: 1em;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.rlc-admin-action-section button {
    background-color: #4f41e4;
    color: white;
}

.rlc-admin-action-section button:hover {
    background-color: #675aec;
}

.rlc-admin-control-section button:hover {
    background-color: white;
}

.bonus-field {
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.bonus-field button {
    width: 35px;
    height: 36px;
    border-radius: 5px 0 0 5px;
    margin: 0;
}

.bonus-field input {
    height: 35px;
    border-radius: 0 5px 5px 0;
    border: 1px solid black;
    margin: 0;
}

.edit-likability {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}

.rlc-edit-button {
    display: flex;
    grid-column: 3/4;
    grid-row: 2/3;
    justify-content: end;
    align-items: end;
}

.rlc-edit-button div{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    background-color: #af2a6f;
    color: white;
}

.rlc-edit-button div:hover {
    cursor: pointer;
    background-color: #c3307c;
}

.action-field {
    display: flex;
    align-items: center;
    gap: 10px;
}

.rlc-jump-button {
    position: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 50%;
    right: 10px;
}

.rlc-jump-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
}

.rlc-admin-register-section {
    max-height: 80vh;
    overflow-y: auto;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.rlc-dropdown {
    position: relative;
    display: inline-block;
    display: 140px;
    padding: 0;
}

.rlc-dropdown button {
    width: 200px;
}

.rlc-dropdown-content {
    display: none;
    position: absolute;
    border-radius: 5px;
    background-color: #f9f9f9;
    min-width: 100%;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
}

.rlc-dropdown:hover .rlc-dropdown-content {
    display: block;
}

.rlc-checkbox-item:hover {
    background-color: #c2c2c2;
}

.rlc-checkbox-item {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px;
}

.rlc-checkbox {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}


@media (min-width: 1024px) {
    .rlc-book-title {
        font-weight: bold;
        font-size: 38px;
    }

    .rlc-admin-control-section {
        width: 100%;
    }

    .rlc-book {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        grid-template-rows: 1fr 2fr;
    }

    .rlc-book-title {
        grid-column: 1/3;
        grid-row: 1/2;
    }

    .rlc-book-data {
        grid-column: 1/3;
        grid-row: 2/3;
    }

    .rlc-student-data {
        grid-column: 2/3;
        grid-row: 2/3;
    }

    .rlc-status-container {
        grid-column: 3/4;
        grid-row: 1/2;
        justify-self: end;
    }

    .rlc-book > div {
        margin-right: 20px;
        flex-grow: 1;
    }

    .rlc-book-title {
        font-size: 20px;
    }
}


@media (max-width: 800px) {

    .rlc-page-content {
        margin: 0px;
    }

    .rlc-book {
        flex-direction: column;
    }

    .rlc-book > div {
        margin: 5px 0;
    }

    .rlc-field {
        width: 100%; 
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    
    .rlc-page-content {
        padding: 10px;
    }

    .rlc-book-title, .rlc-book-author, .rlc-book-pages, .rlc-book-writtenby, .rlc-book-bookid, .rlc-book-reviewid {
        font-size: 12px;
    }

    button {
        width: 100%;
        margin: 5px 0;
        padding: 12px;
        font-size: 12px;
    }

    .rlc-field {
        width: 100%;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .rlc-admin-action-section button, .rlc-admin-control-section button, .bonus-field input, .rlc-admin-control-section input,
    .rlc-admin-control-section select {
        font-size: 0.8em;
    }
}
