.rlc-edit-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  left: 0;
  top: 0;
}

.rlc-edit-data-container {
  display: grid;
  width: 70%;
  height: 80%;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: 50% 50%;
  background-color: white;
  margin-left: 150px;
  border-radius: 10px;
  background-image: linear-gradient(120deg, #2d0038, #5b005f, #ff0078);
}

.edit-book-section {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  margin: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  gap: 10px;
}

.edit-book-section img {
  margin: 5px;
  width: auto;
  height: 300px;
  align-self: center;
}

.edit-book-section textarea {
  resize: none;
}

.edit-fields {
  grid-column: 1/3;
  grid-row: 1/2;
}

.edit-input p, .edit-input textarea, .edit-input input{
  padding: 0;
  margin: 0;
  width: 100%;
}

.edit-input input {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
}

.filler {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.filler textarea{
  padding: 10px;
  border-radius: 5px;
  flex: 1;
}

.edit-image button {
  width: 20%;
  height: 100%;
  background-color: #af2a6f;
  color: white;
}

.edit-image button:hover, .edit-review button:hover, .rlc-edit-button button:hover {
  background-color: #d13786;
}

.image-input-field {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0;
}

.edit-review {
  grid-column: 1/4;
  grid-row: 2/3;
}

.edit-review textarea {
  flex: 1;
  border-radius: 5px;
  resize: none;
}

.edit-review button {
  width: 90%;
  align-self: center;
  margin: 0;
  background-color: #af2a6f;
  color: white;
}

.edit-review p {
  margin: 0;
}
