.mrc-page-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
}

@media (min-width: 768px) {
  .mrc-page-content {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}

@media (min-width: 1024px) {
  .mrc-page-content {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

.glassMorphism {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  backdrop-filter: blur(10px);
  padding: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.mrc-B, .mrc-C, .mrc-D {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 30px;
}

.mrc-B .mrc-score, .mrc-C .mrc-title, .mrc-C .mrc-author, .mrc-C .mrc-pages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
}

.mrc-B input, .mrc-C input, .mrc-D textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.mrc-likeable {
  padding: 15px;
}

.mrc-likeable .form-check {
  margin-top: 10px;
}

.mrc-D textarea {
  flex: 1;
  resize: none;
}

.mrc-D button {
  margin-top: 20px;
  padding: 10px;
}

.add-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.add-img img {
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.add-img button {
  padding: 5px 10px;
  background-color: #ff6666;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .mrc-D button {
    align-self: flex-start;
  }
}
