.cbc-page-content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "b c"
    "b d";
    grid-template-columns:  2.7fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1em;
}


.cbc-book-results{
    width:100%;
}

.cbc-search-result, .cbc-best-result, .cbc-recent-result {
    background-color: var(--background);
    border-radius: 9px 9px 9px 9px;
    border: var(--borders);
    display:flex;
    flex-direction: column;
    min-width: 200px;
    padding: 5px 5px 0px 5px;
    align-items: center;
}

.cbc-search-result{
    overflow-y: auto;
}


.cbc-search-bar{
    padding:0px 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}


.cbc-search-bar input{
    border-radius: 5px;
    border:none;
    width: 90%;
    margin-left: 5px;
    padding-left: 5px;
    height: 40px;
}

.cbc-search-bar svg {
    fill: initial;
    width: 65px;
    height: 65px;
}

.cbc-search-bar svg:hover {
    fill: green;
    opacity: 0.8;
    cursor: pointer;
}

.cbc-search-bar span{
    min-width: 62px;
}
.cbc-search-result {
    grid-area: b;
}

.cbc-best-result {
    grid-area: c;
}

.cbc-recent-result {
    grid-area: d;
}

.cbc-book:hover {
    background-color: #d57eeb48;
    cursor: pointer;
}

.cbc-book {
    display: grid;
    grid-template-areas: 
    "a d" 
    "b d" 
    "c d";
    grid-template-columns: 4fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 10px;
    width: 100%;
    height: 17vh;
    min-height: 50px;
    margin-top: 15px;
    overflow-y: hidden;
   justify-self: center;
}

.inputReview {
    background: linear-gradient(225deg,#f1dce6 10%, #b3adb4 100%);
}
.cbc-book-title {
    grid-area: a;
}

.cbc-book-author {
    grid-area: b;
}

.cbc-book-pages {
    grid-area: c;
}

.cbc-book-img {
    grid-area: d;
    padding: 1em;
    justify-self: end;
}

.cbc-book-img img {
    height: 100%;
    max-width: 60px;
}

.cbc-button {
    height: 30px;
    width: 200px;
    height: 40px;
    letter-spacing: -0.5px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}