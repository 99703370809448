/* Accordion container */
.accordion__section {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    transition: all 0.3s ease;
    display: flex;
    width: 100%;
}

.accordion-container {
    width: 100%;
}

.accordion {
    background-color: #f1f1f1;
    cursor: pointer;
    padding: 15px 15px 5px 15px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: background-color 0.3s ease;
}

.accordion:hover {
    background-color: #e0e0e0;
}

/* Accordion active state */
.accordion.active {
    background-color: #e6f7ff;
    font-weight: bold;
}

/* Chevron rotation when active */
.accordion__icon {
    transition: transform 0.3s ease;
}

.accordion__icon.rotate {
    transform: rotate(180deg);
}

/* Accordion content container */
.accordion__content {
    overflow: hidden;
    transition: max-height 0.3s ease;
    background-color: white;
    padding: 0 15px;
    border-left: 3px solid #007BFF;
    margin-top: 5px;
}

.accordion__text {
    padding: 15px 0;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
}

/* Accordion title styling */
.accordion__title {
    font-size: 16px;
    margin: 0;
    color: #333;
    width: 100%;
    height: auto;
}

/* Buttons and checkboxes within accordion */
.accordion-checkbox {
    margin-top: 8px;
    margin-right: 5px;
}

.test input[type="checkbox"] {
    margin-right: 8px;
    transform: scale(1.2);
}

/* Responsive styles */
@media (max-width: 800px) {
    .accordion__title {
        font-size: 14px;
    }

    .accordion {
        padding: 12px;
    }

    .accordion__text {
        font-size: 13px;
    }
}

@media (max-width: 600px) {
    .accordion__title {
        font-size: 12px;
    }

    .accordion {
        padding: 10px;
    }

    .accordion__text {
        font-size: 12px;
        padding: 10px 0;
    }
}
