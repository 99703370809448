.bl-content-grid {
    display: grid;
    height: 100%;
    grid-template-areas: "searchbar" "table";
    grid-template-rows: 50px auto;
    overflow-y: scroll;
}

.bl-container-table {
    max-height: 100%;
    overflow-y: auto;
}

.bl-container-table tr:nth-child(2n) {
    background-color: rgb(87, 160, 224, 0.2);
}

.admin-table tr:nth-child(2n) {
    background-color: rgb(224, 80, 80, 0.2);
}

.bl-container-table tr:hover {
    cursor: pointer;
    background: #ec6ead9a;
}

.bl-container-table th {
    background-color: rgb(87, 160, 224, 0.5);
}

.admin-table th {
    background-color: rgb(224, 80, 80, 0.5);
}

.bl-table-header th:hover {
    cursor: pointer;
    text-decoration: underline;
}

.bl-searchbar {
    display: flex;
    grid-area: searchbar;
    align-self: center;
    justify-content: center;
    padding: 15px;
}

.bl-searchbar input {
    border-radius: 5px;
    border: none;
    margin-left: 15px;
    width:60%
}

.bl-container-table {
    grid-area: table;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.bl-table{
    margin-bottom: 50px;
    table-layout: fixed;
}

.bl-admin-container-table {
    max-height: 30%;
    overflow-y: auto;
}