.login-page {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    background-image: linear-gradient(120deg, #2d0038, #5b005f, #ff0078);
    display: grid;
    grid-template-areas: 
        "logo logo ." 
        "centerlogin centerlogin centerlogin";
    grid-template-columns: 1fr 1fr;
    overflow: auto;
    box-sizing: border-box;
    font-family: Space Grotesk;

}

.login-page input {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 9px;
    outline: none;
    border: 1px solid;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
}

.logo {
    grid-area: logo;
    align-self: center;
    text-align: center;
}

.logo svg {
    font-size: 2.5em;
    fill: white;
    max-width: 60%;
}

.center-login {
    grid-area: centerlogin;
    justify-self: center;
    align-self: start;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.center-login p {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    color: #aeaeae;
    text-align: center;
}

.login-box, .register-box {
    justify-self: center;
    align-self: start;
    width: 100%;
    max-width: 400px;
    display: grid;
    gap: 1em;
    padding-top: 1em;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 1.5em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.login-user, .login-password, 
.register-user, .register-firstName, .register-lastName, 
.register-password, .register-password2, .register-className, .register-back-button, .register-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
}

.button-gradient {
    background-image: linear-gradient(120deg, #ff0078, #d1006b);
    outline: none;
    border: none;
    color: white;
    font-size: 1rem;
    padding: 0.75rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-gradient:hover {
    background-image: linear-gradient(120deg, #ff0078, #ff60a6);
}

.login-about-button {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    justify-self: end;
    align-self: end;
}

.login-about-svg {
    font-size: 25px;
    color: #95124f;
}

.login-about-svg:hover {
    color: #d1006b;
}

.register-className select {
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid black;
    border-radius: 9px;
    width: 100%;
    font-size: 1rem;
    padding: 10px;
}

.login-interaction-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
}

/* Small devices (phones, < 600px) */
@media only screen and (max-width: 600px) {
    .login-page {
        padding: 1em;
    }

    .center-login {
        width: 90%;
    }

    .logo h1 {
        font-size: 1.8em;
    }

    .center-login p {
        font-size: 1.8rem;
    }

    .login-box, .register-box {
        padding: 1em;
    }

    .button-gradient {
        padding: 0.5rem;
        font-size: 0.9rem;
    }
}

/* Medium devices (tablets, < 768px) */
@media only screen and (max-width: 768px) {
    .center-login {
        width: 80%;
    }

    .center-login p {
        font-size: 2rem;
    }

    .login-box, .register-box {
        padding: 1em;
    }

    .logo svg {
        font-size: 2.5em;
        fill: white;
        max-width: 100%;
    }
}

/* Large devices (desktops and tablets) */
@media only screen and (min-width: 768px) {
    .login-page {
        padding: 2em;
    }
    

    .center-login {
        width: 35%;
    }

    .center-login p {
        font-size: 2.5rem;
    }
}
