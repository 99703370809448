.bpd-edit-container {
  z-index: 11;
}

.bpd-edit-data-container {
  height: 50%;
  grid-template-rows: 80% 20%;

}

.bpd-edit-book-button-section {
  grid-column: 1/4;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  border-radius: 5px;  
}

.bpd-edit-book-button-section button{
  background-color: #af2a6f;
  border: 1px solid white;
  color: white;
  margin: 2px;
}

.bpd-edit-book-button-section button:hover {
  background-color: #d13786;
}

.bpd-edit-book-button-section textarea {
  resize: none;
}