.sidebar-menu {
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 30px);
  min-height: 500px;
}

.sidebar-menu-small {
  margin: 15px 0px 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 30px);
  min-height: 485px;
  max-width: 60px;
}

.sidebar-menu hr{
  border-color:var(--black);
  min-width:195px;
  width:60%;
  margin:auto;
}

.sidebar-logo {
  display: flex;
  justify-content: center;
  width: 240px;
  height: 50px;
  margin: 25px 0px 0px 0px;
  font-size: 30px;
  color: var(--black);
  font-weight: bold;
}

.sidebar-logo-small{
  display: flex;
  justify-content: center;
  height: 50px;
  width: 50px;
  margin: 10px 0px 0px 0px;
  font-size: 30px;
  color: var(--black);
  font-weight: bold
}

.sidebar-logo svg {
  margin-right: 15px;
  height:50px;
}

.sidebar-menu-items {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.sidebar-item {
  height: 10vh;
  width: 100%;
  min-height: 53px;
  max-height: 70px;
  margin: 0px auto;
  font-size: 20px;
  list-style: none;
}



.sidebar-item a {
  display: flex;
  height: 100%;
  width: 100%;
  color: var(--black);
  text-decoration: none;
  border-radius: 5px;
}

.sidebar-item a:hover {
  background-color: #ec6ead93;
  cursor: pointer;
}

.sidebar-item svg {
  width: 25px !important;
  height: 25px !important;

}

.sidebar-link {
  display: flex;
  align-items: center;
  width: 260px;
  margin: 0px auto;
  padding-left: 65px;
  color: var(--black);
}

.sidebar-link-small {
  display: flex;
  align-items: center;
  margin: 0px auto;
  color: var(--black);
}


.sidebar-link-text {
  margin-left: 10px;
  width: 160px;
  font-family: "Space Grotesk";
  /* 65 + 25 + 10 + 160 = 260px (min-width) */
}