.faq-admin-FAQ {
  
  border-radius: 9px 9px 9px 9px;
  border: var(--borders);
  margin: 1em;
  display: flex;
}


.faq-admin-FAQ svg {
  height: 35px !important;
  width: 35px !important;
  margin-left: 10px
}

.faq-admin-FAQ-save:hover {
  color: green;
  cursor: pointer;
}

.faq-admin-FAQ-add {
  width: 35px !important;
  height:35px !important;
  align-self: flex-end;
  margin-right: 18px
}

.faq-admin-FAQ-add:hover {
  color: orange;
  cursor: pointer;
}

.faq-admin-FAQ-trash:hover {
  color: red;
  cursor: pointer;
}

.faq-admin-FAQ-changed[type="text"] {
  background-color: rgb(240, 159, 159);
}



.faq-admin-FAQ-text {
  width: 100%;
}

.faq-admin-FAQ-buttons {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: auto;
}

.faq-admin-FAQ-text input {
  width: 100%;
  border:none;
  margin:5px;
  border-radius:5px;
}
