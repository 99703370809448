.bpd-page-content {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.bdp-button {
  padding: 0.5em !important;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 30px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1); /* Just in case to complement the glassMorphism */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.information-data {
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 15px;
  padding: 30px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1); /* Just in case to complement the glassMorphism */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.title, .author, .pages {
  font-size: 18px;
  font-weight: 500;
}

.title {
  font-size: 22px;
}

.review-button {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  text-align: center;
}

.button-gradient {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.button-gradient:hover {
  background: linear-gradient(135deg, #2575fc, #6a11cb);
}

.image {
  margin-top: 20px;
  text-align: center;
}

.bpd-img {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.description {
  padding: 20px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 1.5;
}

.reviews {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2); /* If you want a slight background change */
  border-radius: 12px;
}


.review-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1); /* Complements your glassMorphism */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.name {
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.review {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-top: 5px;
}

.bpd-control{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 0;
}

.bpd-general-control, .bpd-admin-control {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {
  .information {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
  }

  .title, .author, .pages {
      flex: 1;
  }

  .image {
      margin-top: 0;
  }

  .review-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .name {
      flex: 1;
  }

  .review {
      flex: 2;
      margin-left: 20px;
  }
}
