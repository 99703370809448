.faq-page-content {
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}


.faq-card{
  border-radius: 9px 9px 9px 9px;
  margin: 15px 0px;
  padding: 0px 15px;
}

.faq-card:hover{
  cursor: pointer;
  
}


.faq-question {
    display: flex;
}

.faq-question p {
  margin: 15px 0px 15px 15px;
  font-weight: bold;
}

.tri svg{
  margin-top: 15px;
  width: 20px  !important;
  height:20px !important;
}

.faq-answer p{
  margin-bottom:15px;
}

.expand {
  background-color: red;
}


.faq-cogs{
  position: absolute;
  float: left;
  margin:auto;
  margin-left: calc(100% - 50px);
  width: 40px;
}

.faq-cogs svg{
  color: red;
}



.faq-cogs  svg:hover{
  cursor: pointer;
  color: gray;
}



