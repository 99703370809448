/* variables */

:root {
  --pageHeaderColor: #8c6ad3;
  --pageInnerColor: #7339ee;
  --white: white;
  --navBgColor: hsl(231, 73%, 9%);
  --background: #faebd7;
  --borders: solid 2px #c4c4c4;
  --topListColor: #d69467;
  --barcolor: #ecdfdf;
  --backgroundHover: #faddb7;
  font-family: "Space Grotesk";

  /*Here begins big brain gradient and glass idea*/
  --backgroundGradient: linear-gradient(225deg,#c63f7e 10%, #36223b 100%);
}

.glassMorphism {
    background: white;
    border-radius: 10px;
    border: 3px solid rgba(255, 255, 255, 0.274);
}

/* in Laskuppen-Component */
body {
  margin: 0px;
}

.main-general-styling {
  font-family: "Space Grotesk";
  background-image: var(--backgroundGradient);
  display: grid;
  grid-template: "a b";
  grid-template-columns: 1fr 5fr;
  width: 100%;
  overflow: auto;
}


@media screen and (max-width: 1400px) {
  .main-general-styling {
        grid-template-columns: 65px 1fr;
  }

}
  
.main-sidebar-container {
  grid-area: a;
}

.main-page-container {
  grid-area: b;
  overflow: visible;
}

.page-holder{
  max-height: 100%;
}

/* in each Page-Component*/

.main-page-general-styling {
  display: grid;
  grid-template-areas: "header" "innerContent";
  grid-template-rows: 0.2fr 10fr;
  grid-template-columns: 1fr;
  gap: 15px;
  margin:15px;
  align-items: center;
}

.main-page-header {
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table {
  color: black;
}

.main-page-header h2 {
  color: var(--white);
  color: black;
}
::-webkit-scrollbar {
  width: 0px;
}
.main-page-inner-container {
  max-height: 100%;
  height: 100%;
  color: black;
  min-height: 100%;
  border-radius: 10px 10px 0px 0px;
  grid-area: innerContent;
}

.main-page-content {
  border-radius: 9px 9px 9px 9px;
  width: auto;
  -webkit-overflow-scrolling: touch;
}
