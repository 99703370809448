.top-page-content {
  min-height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr auto;
  gap: 10px;
  background:rgba(255, 255,255, 0) !important;
}

.top-page-content th:hover{
  cursor: pointer;
  text-decoration: underline;
}

.top-top-reader-card {
  grid-column: 1/2;
  grid-row: 1/2
}

.top-top-book-card {
  grid-column: 1/2;
  grid-row: 2/3;
}

.top-my-points {
  grid-column: 2/3;
  grid-row: 1/2;
}

.top-my-recommendation {
  grid-column: 2/3;
  grid-row: 2/3;
}

.top-top-student-list {
  grid-column: 1/2;
  grid-row: 3/4;
}

.top-top-class-list {
  grid-column: 2/3;
  grid-row: 3/4;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom:10px;
}

.top-inner-recommendation {
  display: grid;
  grid-template-areas: "a d" "b d" "c d";
  grid-template-columns: 4fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 1em;
  width: 100%;
}

.top-top-lists {
  grid-area: top-lists;
  display: grid;
  grid-template-areas: "table";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;
}

.top-tableDiv {
  border-radius: 9px;
  grid-area: table;
  background: var(--white);
  background:rgba(0, 0,0, 0);
  overflow-y: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
}

.top-filter-label {
  grid-area: filter;
  align-self: center;
  justify-self: center;
}

.top-nameButton {
  border: var(--borders);
  background: var(--barcolor);
  grid-area: student;
  border-radius: 9px 9px 0px 0px !important;
  border-style: solid solid hidden solid;
  background:rgba(0, 0,0, 0);
}

.top-classButton {
  border: var(--borders);
  background: var(--barcolor);
  grid-area: class;
  border-radius: 9px 9px 0px 0px !important;
  border-style: solid solid hidden solid;
  background:rgba(0, 0,0, 0);
}

.top-pointsButton {
  border: var(--borders);
  background: var(--barcolor);
  grid-area: points;
  border-radius: 9px 9px 0px 0px !important;
  border-style: solid solid hidden solid;
  background:rgba(0, 0,0, 0);
}

.top-readBooksButton {
  border: var(--borders);
  background: var(--barcolor);
  grid-area: readbooks;
  border-radius: 9px 9px 0px 0px !important;
  border-style: solid solid hidden solid;
  background:rgba(0, 0,0, 0);
}

.top-table-holder {
  border: var(--borders);
  background: var(--background);
  background:rgba(0, 0,0, 0);
}

hr {
  width: 90%;
  border-color: black;
  margin: 0px;
  margin-left: 1em;
}

.top-card-title {
  margin: 0px;
  text-align: center;
}

.top-card-text{
  margin: 0px;
  margin-top: 1em;
  margin-left: 1em;
}

.cbc-book-img {
  grid-area: d;
  padding: 1em;
}

.cbc-book-img img {
  max-width: 100px !important;
}

.top-my-recommendation:hover,.top-top-book-card:hover {
  cursor: pointer;
  background-color: #ec6ead93;
}

.recommended-wrapper {
  display: flex;
  flex-direction: row;
  padding: 1em;
  justify-content: space-between;
}

.table-section {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid lightgray;
  border-radius: 9px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination span {
  padding: 5px;
  color: gray
}

.table {
  flex: 1;
}

td {
  line-height: 10px;
  padding: 0;
}

.top-top-class-list h2 {
  margin-bottom: 40px;
}

.top-top-student-list select {
  padding: 5px;
  border-radius: 5px;
}

.selectClass:hover {
  cursor: pointer;
}


@media screen and (max-width: 800px) {

  .top-page-content {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
  }

  .top-top-reader-card {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .top-my-points {
    grid-column: 1/2;
    grid-row: 2/3;
  }
    
  .top-top-book-card {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  
  .top-my-recommendation {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  
  .top-top-student-list {
    grid-column: 1/2;
    grid-row: 5/6;
  }
  
  .top-top-class-list {
    grid-column: 1/2;
    grid-row: 6/7;
  }

  .cbc-book-img{
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .top-page-content{
    display: grid;
    grid-template-areas: 
    "my-points" 
    "top-lists";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 3fr;
  }
  .top-my-points{
    text-align: center;
  }
  .top-top-reader-card, .top-top-book-card, .top-my-recommendation, .top-inner-recommendation {
    display: none;
  }
}

